import { PageProps } from 'gatsby';
import { FC } from 'react';
import tw from 'twin.macro';
import {
  ContentSection,
  Footnotes,
  H2,
  Hero,
  HR,
  Layout,
  NERLYNX,
  References,
  SEO,
  Space,
} from '~/components';
import { Frontmatter } from '~/config';
import heroBackground from '../images/hero-book.jpg';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Guidelines',
  mobileNavTitle: 'Clinical Guidelines',
  desktopNavTitle: 'Clinical \\nGuidelines',
  order: 4,
  screenShoot: true,
  anchors: [
    {
      id: 'early-stage-breast-cancer',
      label: 'EARLY-STAGE BREAST CANCER',
      ariaLabel: 'Early Stage Breast Cancer',
    },
    {
      id: 'metastatic-breast-cancer',
      label: 'METASTATIC BREAST CANCER',
      ariaLabel: 'Metastatic Breast Cancer',
    },
  ],
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout anchors={frontmatter.anchors}>
      <SEO
        title="Clinical guidelines | NERLYNX® (neratinib) tablets"
        description="See the clinical guidelines for NERLYNX in early-stage and metastatic HER2+ breast cancer."
        path={props.location?.pathname}
      />
      <Hero
        backgroundImage={heroBackground}
        headlinePadding={'right'}
        titleStyle={tw`mb-40 -mt-4 md:(my-0)`}
        backgroundStyle={tw`background-position[83% 0%] background-size[auto 130%] md:(bg-cover bg-right)`}
      >
        Guideline recommendations for <NERLYNX />
      </Hero>
      <section id="early-stage-breast-cancer">
        <ContentSection>
          <H2 cyan>
            Neratinib (<NERLYNX />
            ®) is included as a treatment option for HER2+ eBC in the NCCN
            Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for
            Breast Cancer<sup>1</sup>
          </H2>
          <div tw="flex flex-col font-tertiary my-8">
            <div tw="w-full background[#4E5055]">
              <h3 tw="py-2 mt-0 text-base text-center text-white">
                NCCN Guidelines<sup>®</sup> Inclusions
              </h3>
            </div>
            <div tw="bg-brand-pastel-gray flex flex-col text-center justify-center items-center px-4 pt-0 pb-8 lg:(px-8 pt-0 pb-12)">
              <h3 tw="text-transform[initial] py-6 px-4 mt-0 text-sm text-brand-gray">
                Useful in certain circumstances for patients with HER2+ eBC
                <sup>1</sup>
              </h3>
              <div tw="flex flex-col justify-center items-stretch gap-6 pt-0 lg:(flex-row)">
                <div tw="text-center color[#485060] flex flex-col justify-between items-center gap-4 bg-brand-yellow w-full p-6 py-8 lg:(w-[60%] gap-2 p-4)">
                  <p tw="py-0 my-0 font-bold font-size[17px]">Category 2A*</p>
                  <p tw="py-0 my-0 text-sm w-full lg:(w-[79%])">
                    Consider extended adjuvant neratinib (<NERLYNX />) following
                    adjuvant trastuzumab-containing therapy for patients with
                    HER2+ HR+ eBC with a perceived high risk of recurrence
                    <sup>1,†</sup>
                  </p>
                  <p tw="py-0 my-0 font-bold font-size[17px] lg:(text-xs)">
                    ExteNET (NCT00878709)
                  </p>
                </div>
                <div tw="text-center flex flex-col justify-between gap-4 bg-brand-blue w-full p-6 py-8 text-white text-sm lg:(w-[40%] gap-2 p-4 justify-start)">
                  <p tw="py-0 my-0 font-bold font-size[17px]">
                    Dose escalation
                  </p>
                  <p tw="py-0 my-0 text-sm">
                    The NCCN Guidelines recommend dose escalation of neratinib (
                    <NERLYNX />)<sup>1</sup>
                  </p>
                  <p tw="py-0 my-0 font-bold font-size[17px] lg:(text-xs)">
                    CONTROL (NCT02400476)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footnotes
            footnotes={['category-2a', 'extended-neratinib']}
            references={['1', '1', '']}
            abreviations={['eBC', 'HR+']}
            tw="mb-4"
          />
          <div className="footnote" tw="self-end">
            NCCN makes no warranties of any kind whatsoever regarding its
            content, use, or application and disclaims any responsibility for
            how its content is applied or used, in any way.
          </div>
        </ContentSection>
      </section>
      <section id="metastatic-breast-cancer">
        <ContentSection flavor="dark">
          <H2 cyan tw="mb-8 md:(mb-10)">
            Neratinib (<NERLYNX />
            ®) is included as part of 2 treatment options for recurrent HER2+
            breast cancer CNS metastases in the NCCN Guidelines for Central
            Nervous System Cancers<sup>2</sup>
          </H2>
          <div tw="flex flex-col font-tertiary my-8">
            <div tw="w-full background[#4E5055]">
              <h3 tw="py-2 mt-0 text-base text-center text-white">
                NCCN Guidelines Inclusions
              </h3>
            </div>
            <div tw="bg-brand-pastel-gray flex flex-col text-center justify-center items-center px-4 pt-0 pb-8 lg:(px-8 pt-0 pb-12)">
              <h3 tw="text-transform[initial] py-6 px-4 mt-0 text-sm text-brand-gray">
                Category 2A*: recommended for brain metastases
                <sup>2</sup>
              </h3>
              <div tw="flex flex-col justify-center items-stretch gap-6 pt-0 lg:(flex-row)">
                <div tw="text-center flex flex-col justify-between items-center gap-4 bg-brand-pink w-full p-6 py-8 lg:(w-[60%] gap-2 p-4)">
                  <p tw="py-0 my-0 font-bold font-size[17px]">
                    Phase 3 trial<sup>†</sup>
                  </p>
                  <p tw="py-0 my-0 text-sm w-full lg:(w-[70%])">
                    Consider neratinib (<NERLYNX />) + capecitabine to treat
                    patients with stable, asymptomatic HER2+ breast cancer brain
                    metastases
                    <sup>2</sup>
                  </p>
                  <p tw="py-0 my-0 font-bold font-size[17px]">
                    NALA (NCT01808573)
                  </p>
                </div>
                <div tw="text-center flex flex-col justify-between gap-4 bg-brand-pink w-full p-6 py-8 text-white text-sm lg:(w-[40%] gap-2 p-4)">
                  <p tw="py-0 my-0 font-bold font-size[17px]">
                    Phase 2 trial<sup>‡</sup>
                  </p>
                  <p tw="py-0 my-0 text-sm">
                    Consider neratinib (<NERLYNX />) + capecitabine to treat
                    patients with progressive HER2+ breast cancer brain
                    metastases
                    <sup>2</sup>
                  </p>
                  <p tw="py-0 my-0 font-bold font-size[17px]">
                    TBCRC 022 (NCT01494662)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footnotes
            footnotes={['category-2a', 'phase-3', 'phase-2']}
            references={['2', '2,3', '2']}
            abreviations={'CNS'}
            tw="mb-2"
          />
          <div className="footnote" tw="self-end text-white mb-2">
            NCCN makes no warranties of any kind whatsoever regarding its
            content, use, or application and disclaims any responsibility for
            how its content is applied or used, in any way.
          </div>
          <div className="footnote" tw="self-end text-white">
            The National Comprehensive Cancer Network<sup>®</sup> (NCCN
            <sup>®</sup>) Guidelines are produced completely independently. NCCN
            Guidelines are not intended to promote any specific therapeutic
            modality. They may contain information that may differ from or is
            not included in the Full Prescribing Information for neratinib (
            <NERLYNX />
            ).
          </div>
          <Space xxxl />
        </ContentSection>
      </section>
      <HR yellow />
      <ContentSection>
        <References
          references={['referenced for bc', 'referenced for cnsc', 'saura c']}
        />
      </ContentSection>
    </Layout>
  );
};

export default Page;
